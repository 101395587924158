export function isLocalStorageAvailable(): boolean {
  return typeof localStorage?.getItem === 'function';
}

export function getValueFromStorage(key: string, defaultValue: string | null = null) {
  if (isLocalStorageAvailable()) {
    const stored = localStorage?.getItem(key);
    if (stored !== null) {
      return stored;
    }
  }
  return defaultValue;
}

export function setValueInStorage(key: string, value: string | undefined) {
  if (isLocalStorageAvailable()) {
    if (typeof value === 'undefined') {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, value);
    }
  }
}
