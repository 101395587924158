export function getMediaPreference() {
  if (typeof window.matchMedia === 'function') {
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  }
  return 'light';
}

export function setDocumentClassName(theme: string) {
  window.document.documentElement.className = theme;
}
