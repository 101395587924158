import {ref} from 'vue';
import {defineStore} from 'pinia';
import {getValueFromStorage, setValueInStorage} from '@/helpers/storage.ts';

export type AppThemeStore = ReturnType<typeof useAppThemeStore>;

export const useAppThemeStore = defineStore('appTheme', () => {
  const zoom = ref(getStoredValue());

  function correctValue(value: number) {
    return Math.min(150, Math.max(100, value));
  }

  function setZoom(value: number) {
    const clampedValue = correctValue(value);
    zoom.value = clampedValue;
    setValueInStorage('predikacio_font_size', clampedValue.toString());
  }

  function getStoredValue() {
    const defaultValue = 100;
    let storedValue = defaultValue;
    if (typeof localStorage?.getItem === 'function') {
      storedValue = Number(
        getValueFromStorage('predikacio_font_size', String(defaultValue)) || defaultValue
      );
    }
    return correctValue(storedValue);
  }

  return {
    zoom,
    setZoom,
  };
});
